import dayjs from '@/utils/day';

export default function useCampingCard({
  campingcard,
  campsite: cp,
  campsiteSlug,
}: {
  campingcard?: any;
  campsite?: MaybeRefOrGetter<any | null>;
  campsiteSlug: string;
}) {
  const campsite = toRef(cp);

  /********************
   * COMPOSITIONS      *
   ********************/
  const backendStore = useBackendStore();
  const { $gettext, interpolate, $pgettext } = useGettext();
  const { locale } = useI18n();

  /********************
   * REFS & VARS       *
   ********************/
  const campingCardShopUrl = computed(() => {
    const lang = locale.value;
    const urlMapping = {
      en: 'https://tc.tradetracker.net/?c=31855&m=12&a=124232&r=acsi-cca-en&u=%2Fen%2Fcampingcard-acsi-campinginfo',
      de: 'https://analytics.acsi.eu/c?c=31856&m=12&a=88364&r=acsi-cca-de&u=%2Fde%2Fcampingcard-acsi-campinginfo',
      nl: 'https://analytics.acsi.eu/c?c=31854&m=12&a=124231&r=acsi-cca-nl&u=%2Fnl%2Fcampingcard-acsi-campinginfo',
      fr: 'https://analytics.acsi.eu/c?c=31857&m=12&a=440657&r=acsi-cca-fr&u=%2Ffr%2Fcampingcard-acsi-campinginfo',
      es: 'https://analytics.acsi.eu/c?c=35333&m=12&a=440656&r=acsi-cca-es&u=%2Fes%2Fcampingcard-acsi-campinginfo',
      default:
        'https://tc.tradetracker.net/?c=31855&m=12&a=124232&r=acsi-cca-default&u=%2Fen%2Fcampingcard-acsi-campinginfo',
    };
    return urlMapping[lang] || urlMapping.default;
  });

  const moreInfoLink = computed(() => {
    return getCampingCardShopUrl('campsite-detail-acsi-cc-info-modal');
  });

  const i18nCampingCardInfoText2 = computed(() => {
    const translated = $gettext('In these periods you pay only %{rate} € per night.');
    return interpolate(
      translated,
      {
        rate: campsite.value?.price?.campingcard?.rate || campingcard?.rate || 0,
      },
      true,
    );
  });

  const i18nCampingCardInfoAdditionalInfo = computed(() => {
    const txt = $gettext('You don\'t have a CampingCard ACSI yet? %{buyNowLink}');
    const linkText = $gettext('Buy now');
    const buyNowLink = `<a href="${moreInfoLink.value}" target="_blank" rel="nofollow">${linkText}</a>`;
    return interpolate(txt, { buyNowLink }, true);
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function getCampingCardShopUrl(linkIdentifier: string) {
    return `${backendStore.url}/external-link/?lang=${locale.value}&url=${encodeURIComponent(
      campingCardShopUrl.value,
    )}&slug=${campsiteSlug}&link_identifier=${linkIdentifier}`;
  }

  function getLocalizedPeriod(period) {
    const fromto = $pgettext('"date from" to "date until", e.g. "2023-01-01 to 2023-02-02"', '%{from} to %{until}');
    return interpolate(
      fromto,
      {
        from: dayjs(period.start_date).format('L'),
        until: dayjs(period.end_date).format('L'),
      },
      true,
    );
  }

  return {
    campingCardShopUrl,
    getCampingCardShopUrl,
    getLocalizedPeriod,
    i18nCampingCardInfoAdditionalInfo,
    i18nCampingCardInfoText2,
    moreInfoLink,
  };
}
